import { Shape } from "../Shape";
import moment from "moment";
import { STAGE_NAMES } from "utils/enums";
import { RootStage } from "./RootStage";
import { CreateStageType } from "./create-stage.type";
import { hexToRgb, LIMIT_SHAPES } from "../utils";

let canvas;

let movingCorner = -1;
export class StagePaiting extends RootStage {
  public initialMovement: any;
  public executingScript = false;
  public dragging = false;
  public shapeSelected = -1;
  public shapeInUse: any;
  public dragoffx = 0;
  public init = true;
  public acertosExecutados;
  public dragoffy = 0;
  public clone: any;
  public stageLiveMode: any;
  public shapesDeleteds: Shape[] = [];
  public readyToStart = false;
  public colorPainting = "#ffffff";
  public paintingType = "";

  constructor({
    id,
    canvasDoc,
    ids = [],
    shapes = [],
    quandoIniciar = [],
    quandoTerminar = [],
    acertosDoCenario = 0,
    isPlayTEA = false,
    mecanic = STAGE_NAMES.PAINTING,
    paintingType = "",
    colorPainting = "",
  }: CreateStageType) {
    super({
      id,
      canvasDoc,
      ids,
      shapes,
      quandoIniciar,
      quandoTerminar,
      acertosDoCenario,
      isPlayTEA,
      mecanic,
    });
    this.clock = "";
    this.id = id;
    canvas = canvasDoc;
    this.context = canvas.getContext("2d");
    this.updateSizeCanvas(isPlayTEA);
    this.selectedShapes = [];
    this.shapes = [...shapes, this.selectedGroupShapes];
    this.ids = ids;
    this.name = "";
    this.initialMovement = null;
    this.isCurrentStage = false;
    this.executingScript = false;
    this.dragging = false;
    this.shapeSelected = -1;
    this.shapeInUse = null;
    this.dragoffx = 0;
    this.init = true;
    this.acertosExecutados = 0;
    this.acertosDoCenario = acertosDoCenario;
    this.dragoffy = 0;
    this.quandoIniciar = quandoIniciar;
    this.quandoTerminar = quandoTerminar;
    this.clone = null;
    this.stageLiveMode = null;
    this.shapesDeleteds = [];
    this.readyToStart = false;
    this.paintingType = paintingType;
    this.colorPainting = colorPainting;
    this.corners = {
      shapes: [],
      father: -1,
    };
    this.liveMode = false;
    this.actions = [];
  }

  clickAction(e) {
    this.clear(this.ghostctx);

    movingCorner = super.clickAction(e);

    return movingCorner;
  }

  addShape(shapes: Shape[] = []) {
    this.shapes = this.shapes.concat(shapes);
  }

  acceptMovingPaiting() {
    return 15000;
  }

  async start(force = false, gameState, liveMode?: boolean) {
    if (!this.looping || force) {
      this.isCurrentStage = true;

      this.clock = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
      this.draw();

      if (!liveMode) {
        this.looping = setInterval(() => {
          this.draw();
        }, 1000 / this.fps);
      }
    }
  }

  checkPaiting(gameState) {
    const filteredShapes = this.shapes
      .sort((a, b) => b.zIndex - a.zIndex)
      .filter((shape) => shape.id !== LIMIT_SHAPES);
    for (const shape of filteredShapes) {
      let quantityPainted: number[][] = [];
      if (shape) {
        const imageData = this.context.getImageData(
          shape.x,
          shape.y,
          shape.width,
          shape.height
        );

        for (let i = 0; i < imageData.data.length; i += 4) {
          const rgb =
            imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2];

          if (rgb !== 765) {
            quantityPainted.push([i, i + 1, i + 2, 1]);
          }
        }

        shape.updateTempImageData(quantityPainted);

        if (shape.imageDataSize) {
          const hexCalc = hexToRgb(this.colorPainting);

          for (const dataIndex in shape.imageDataSize) {
            if (
              imageData.data[shape.imageDataSize[dataIndex][0]] >= hexCalc.r &&
              imageData.data[shape.imageDataSize[dataIndex][1]] >= hexCalc.g &&
              imageData.data[shape.imageDataSize[dataIndex][2]] >= hexCalc.b
            ) {
              shape.imageDataSize.splice(Number(dataIndex), 1);
            }
          }
        }

        if (!shape.painted && shape.imageDataSize.length < 100) {
          shape.painted = true;
          this.acertosExecutados += 1;
          let quandoAcertar = Object.keys(shape.quandoAcertar);

          if (quandoAcertar.length) {
            this.playScript({
              type: quandoAcertar,
              name: "quandoAcertar",
              script: shape.quandoAcertar,
              shapeSelected: Number(shape.id),
              gameState,
            });
          }
          this.shapeInUse = null;
          if (this.acertosExecutados >= Number(this.acertosDoCenario)) {
            this.acertosExecutados = 0;
            this.shapeInUse = null;
            this.startMovement = false;
            this.init = false;
            this.playScriptTerminar(null, gameState);
          }

          break;
        }
      }
    }
  }

  moveAction(e, gameState) {
    if (!this.liveMode) {
      super.moveAction(e, gameState);
    } else {
      //  logica de pintar e limpar
      const mouse = this.mouse.getPosition(e, canvas);

      const ctx = this.context;

      this.shapeInUse = null;

      const shapesSorted = this.shapes.sort((a, b) => b.zIndex - a.zIndex);
      for (let i = 0; i < shapesSorted.length; i++) {
        if (this.shapes[i].contains(mouse.x, mouse.y)) {
          this.shapeInUse = this.shapes[i];
          break;
        }
      }

      if (
        this.startMovement &&
        this.colorPainting &&
        this.shapeInUse &&
        this.shapeInUse.active &&
        this.shapeInUse.canPaint &&
        !this.shapeInUse.painted
      ) {
        ctx.moveTo(mouse.x, mouse.y);
        ctx.beginPath();
        ctx.arc(mouse.x, mouse.y, 10, 0, Math.PI * 2, false);
        ctx.closePath();
        const rgb = hexToRgb(this.colorPainting);
        ctx.fillStyle = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
        ctx.fill();
      }
      this.checkPaiting(gameState);
    }
  }

  setLiveMode(flag, gameState) {
    this.liveMode = flag;
    if (this.liveMode) {
      super.setLiveMode(flag, gameState);
      this.draw();
      clearInterval(this.looping);
    } else {
      this.clear(this.context);
      this.looping = setInterval(() => {
        this.context.globalCompositeOperation = "source-over";
        this.draw();
      }, 1000 / this.fps);
    }
  }

  async dropAction(_, gameState) {
    this.startMovement = false;
    if (!this.liveMode) {
      this.oldY = 0;
      this.oldX = 0;
      this.startMovement = null;

      super.dropAction(_, gameState);

      this.dragging = false;
    }
  }
}
