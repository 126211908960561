import React from "react";
import { Mecanics } from "../../mecanics";
import { Modal } from "antd";

export const ModalMecanics = ({ visible, setVisible, action, showFooter }) => {
  return (
    <Modal
      title="Selecione uma mecânica"
      width={1000}
      closable={false}
      cancelText={null}
      okText="Criar fase"
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      cancelButtonProps={{ className: "common--secondary-submit-button" }}
    >
      <Mecanics
        onClick={(mecanic) => {
          setVisible(false);

          action(mecanic);
        }}
      />
    </Modal>
  );
};
