import { CORNERS_SHAPE_NAME, STAGE_NAMES } from "utils/enums";
import { Shape } from "../Shape";
import { IStages } from "./IStages";
import $ from "jquery";
import _ from "lodash";
import { Mouse } from "../Mouse";
import { CreateStageType } from "./create-stage.type";
import { LIMIT_SHAPES } from "../utils";

let canvas;
let movingCorner = -1;

export const playTEACreatorDimensions = {
  width: 1280,
  height: 720,
  minDimensionsShape: 30,
};

type PlayScriptType = {
  type: any;
  name: string;
  script: any;
  callbackActive?: any;
  callbackInactive?: any;
  gameState: any;
  init?: boolean;
  shapeSelected?: number;
};

export class RootStage implements IStages {
  public corners: { shapes: Shape[]; father: number } = {
    shapes: [],
    father: -1,
  };

  public readyToStart = false;
  public sceneScripts?: any;
  public looping?: any;
  public name = "";
  public init?: boolean;
  public width?: number;
  public height?: number;
  public shapeInUse?: any;
  public shapeSelected?: any;
  public liveMode?: boolean;
  public context: any;
  public selectedGroupShapes?: any;
  public canvas: any;
  public ghostCanvas: any;
  public ghostctx: any;
  public resized: any;
  public isCurrentStage?: boolean;
  public oldX = 0;
  public oldY = 0;
  public fps = 60;
  public selectedShapes: Shape[] = [];
  public id?: number;
  public mouse = new Mouse();
  public clock = "";
  public startMovement?: any;
  public middleMovement?: any;
  public openConfigs?: boolean;
  public actions: any;
  public mecanic: string = STAGE_NAMES.PAIRING;
  public ids;
  public shapes: Shape[] = [];
  public quandoIniciar = [];
  public quandoTerminar = [];
  public acertosDoCenario: number;
  public isPlayTEA: boolean;
  public shapesDeleteds: Shape[] = [];
  public dragoffx = 0;
  public dragoffy = 0;
  public dragging = false;

  constructor({
    id,
    canvasDoc,
    ids,
    shapes,
    quandoIniciar = [],
    quandoTerminar = [],
    acertosDoCenario,
    isPlayTEA,
    mecanic,
  }: CreateStageType) {
    canvas = canvasDoc;

    this.id = id;
    this.ids = ids;
    this.shapes = shapes ?? [];
    this.quandoIniciar = quandoIniciar;
    this.quandoTerminar = quandoTerminar;
    this.acertosDoCenario = Number(acertosDoCenario);
    this.isPlayTEA = Boolean(isPlayTEA);
    this.mecanic = String(mecanic);
    this.looping = null;
    this.selectedGroupShapes = new Shape({
      hidden: true,
      clickable: false,
      visible: false,
      height: 0,
      width: 0,
      x: 0,
      y: 0,
      id: LIMIT_SHAPES,
      zIndex: -1000000,
    });
  }

  start(force: any, gameState: any, isPlayTEA?: boolean): Promise<void> {
    throw new Error("Override method");
  }

  checkBoxSelectingGroup(mouse: Mouse) {
    this.selectedGroupShapes.draw(this.ghostctx, this.liveMode);
    const imageData = this.ghostctx.getImageData(mouse.x, mouse.y, 1, 1);
    if (imageData.data[3] > 0) {
      this.dragoffx = mouse.x - this.selectedGroupShapes.x;
      this.dragoffy = mouse.y - this.selectedGroupShapes.y;
      this.selectedGroupShapes.posInitialX = this.selectedGroupShapes.x;
      this.selectedGroupShapes.posInitialY = this.selectedGroupShapes.y;
      this.shapeSelected = Number(
        this.shapes?.findIndex(
          (shape) => shape.id === this.selectedGroupShapes.id
        )
      );
      this.selectedShapes.map((shape: Shape) => {
        shape.dragoffx = mouse.x - shape.x;
        shape.dragoffy = mouse.y - shape.y;
        return shape;
      });
    } else {
      this.clearSelectedGroupShapes();
      this.startMovement = _.cloneDeep(mouse);
    }
  }

  clickAction(event: any) {
    if (this.isCurrentStage && this.init) {
      let mouse = this.mouse.getPosition(event, canvas);
      if (this.selectedGroupShapes.visible) {
        this.checkBoxSelectingGroup(mouse);
      } else {
        this.startMovement = _.cloneDeep(mouse);
        for (let i = this.shapes.length - 1; i >= 0; i--) {
          this.shapes[i].draw(this.ghostctx, this.liveMode);
          const imageData = this.ghostctx.getImageData(mouse.x, mouse.y, 1, 1);
          if (imageData.data[3] > 0) {
            this.shapeSelected = i;
            this.shapeInUse = this.shapes[this.shapeSelected];
            this.createCorners();

            if (this.shapeInUse.isMoving) {
              this.shapeSelected = -1;
              this.shapeInUse = null;
            } else {
              this.dragoffx = mouse.x - this.shapeInUse.x;
              this.dragoffy = mouse.y - this.shapeInUse.y;
              this.shapeInUse.posInitialX = this.shapeInUse.x;
              this.shapeInUse.posInitialY = this.shapeInUse.y;
            }
            break;
          }

          this.shapeSelected = -1;
          this.shapeInUse = null;
        }
      }

      if (this.shapeInUse?.id) {
        for (let corner = 0; corner < this.corners.shapes.length; corner++) {
          if (this.corners.shapes[corner].contains(mouse.x, mouse.y)) {
            movingCorner = corner;
            this.styleCanvasCorners(corner);
            break;
          }
          canvas.style.cursor = "default";
        }
      }

      if (this.shapeSelected === -1) {
        movingCorner = -1;
        this.shapeInUse = {};
        this.corners.shapes = [];
        this.corners.father = -1;
        return movingCorner;
      } else {
        this.dragging = true;
      }
    }

    return movingCorner;
  }

  moveAction(event: any, __): void {
    if (
      this.liveMode &&
      (!this.shapeInUse?.clickable || !this.shapeInUse?.active)
    ) {
      this.dragging = false;
      return;
    }

    if (this.isCurrentStage && this.init) {
      const mouse = this.mouse.getPosition(event, canvas);
      this.middleMovement = _.cloneDeep(mouse);
      if (this.startMovement && !this.shapeInUse?.id && !this.liveMode) {
        this.drawSelectionRectangle({
          selectionEndX: this.middleMovement?.x,
          selectionEndY: this.middleMovement?.y,
          selectionStartX: this.startMovement?.x,
          selectionStartY: this.startMovement?.y,
        });
      }
      for (let corner = 0; corner < this.corners.shapes.length; corner++) {
        if (this.corners.shapes[corner].contains(mouse.x, mouse.y)) {
          this.styleCanvasCorners(corner);
          break;
        }
        canvas.style.cursor = "default";
      }

      if (
        this.selectedGroupShapes.visible &&
        this.selectedShapes.length &&
        this.dragging
      ) {
        this.shapeInUse.x = mouse.x - this.dragoffx;
        this.shapeInUse.y = mouse.y - this.dragoffy;
        this.selectedShapes.map((shape) => {
          shape.x = mouse.x - shape.dragoffx;
          shape.y = mouse.y - shape.dragoffy;
          return shape;
        });
        this.openConfigs = false;
        return;
      }

      if (movingCorner >= 0) {
        return this.loadCorners(mouse, movingCorner);
      }

      if (this.dragging || (this.dragging && !this.liveMode)) {
        if (this.shapeInUse && !this.shapeInUse.used) {
          this.shapeInUse.x = mouse.x - this.dragoffx;
          this.shapeInUse.y = mouse.y - this.dragoffy;

          if (!this.liveMode) this.createCorners();
        }

        this.openConfigs = false;
      }
    }
  }

  /**
   *
   * @param {Shape} shape
   * @param {Shape} clone
   * @param {Number} collision - -1 - always, 0 - low, 1 - middle, 2 - high (using 10% off size);
   */
  checkCollision(shape, clone, collision = 2) {
    if (collision === -1) return true;

    let catX = shape.centerX() - clone.centerX();
    let catY = shape.centerY() - clone.centerY();
    let halfWidth = shape.halfWidth() + clone.halfWidth();
    let halfHeight = shape.halfHeight() + clone.halfHeight();
    if (collision === 1) {
      return (
        Math.abs(catX) + shape.halfWidth() < halfWidth &&
        Math.abs(catY) + shape.halfHeight() < halfHeight
      );
    } else if (collision === 2) {
      return (
        Math.abs(catX) + shape.width - (shape.width * 10) / 100 <= halfWidth &&
        Math.abs(catY) + shape.height - (shape.height * 10) / 100 <= halfHeight
      );
    }

    return Math.abs(catX) < halfWidth && Math.abs(catY) < halfHeight;
  }

  verifyShapesInSelected() {
    this.selectedShapes = [];
    for (const shape of this.shapes.filter(
      (shape) => Number(shape?.id) < LIMIT_SHAPES
    )) {
      if (
        this.checkCollision(this.selectedGroupShapes, shape, 1) ||
        this.checkCollision(shape, this.selectedGroupShapes, 1)
      ) {
        this.selectedShapes.push(shape);
      }
    }

    if (!this.selectedShapes.length) {
      this.selectedGroupShapes.visible = false;
    } else {
      this.shapeInUse = this.selectedGroupShapes;
    }
    return true;
  }

  async dropAction(_: Event, __: any): Promise<void> {
    const { shapeInUse } = this;
    this.verifyShapesInSelected();

    movingCorner = -1;
    if (
      shapeInUse?.id &&
      (shapeInUse.x !== shapeInUse.posInitialX ||
        shapeInUse.y !== shapeInUse.posInitialY)
    ) {
      shapeInUse.normalizeSize();
      shapeInUse.setData([
        {
          info: "x",
          auxInfo: "posInitialX",
        },
        {
          info: "y",
          auxInfo: "posInitialY",
        },
      ]);
    }
  }

  async playScriptTerminar(_, gameState: any = {}) {
    gameState.executeScriptTerminated();
  }

  async playScript({
    type,
    name,
    script,
    callbackActive,
    gameState,
    init = true,
    callbackInactive,
    shapeSelected = -1,
  }: PlayScriptType) {
    if (gameState) {
      gameState.executeScriptInitiate({
        type,
        init,
        name,
        script,
        callbackActive,
        callbackInactive,
        shapeSelected,
      });
    }
  }

  filterShapesLiveMode(shape) {
    return this.liveMode ? shape.id !== LIMIT_SHAPES : true;
  }

  draw() {
    let shapeSorted = this.shapes
      .sort((a, b) => a.zIndex - b.zIndex)
      .filter((shape) => this.filterShapesLiveMode(shape));

    const rootContext = this.getContext();
    const auxContext = this.ghostctx;
    this.clearRectCanvas();

    shapeSorted.forEach((shape) => {
      if (shape.animated) {
        if (this.liveMode) {
          shape.animating(rootContext, [
            { width: canvas.width, height: canvas.height },
          ]);
        }
      }

      shape.draw(rootContext, this.liveMode);
      shape.draw(auxContext, this.liveMode);
    });

    if (this.corners.shapes.length > 0) {
      this.corners.shapes.forEach((shape) => {
        shape.draw(rootContext, this.liveMode);
        shape.draw(auxContext, this.liveMode);
      });
    }
  }

  clearRectCanvas() {
    this.getContext().clearRect(0, 0, this.width, this.height);
    this.ghostctx.clearRect(0, 0, this.width, this.height);
  }

  clear(ctx) {
    if (ctx) {
      return ctx.clearRect(0, 0, this.width, this.height);
    }

    this.clearRectCanvas();
    this.draw();
  }

  pauseScene() {
    this.isCurrentStage = false;
    this.liveMode = false;
    this.clear(this.context);
  }

  stopRenderCanvas() {
    clearInterval(this.looping);
  }

  getLastIdShape() {
    return (
      this.shapes
        ?.filter((shape) => shape.id !== LIMIT_SHAPES)
        ?.sort((a, b) => Number(b?.id) - Number(a?.id))[0]?.id || 0
    );
  }

  styleCanvasCorners(corner) {
    switch (corner) {
      case CORNERS_SHAPE_NAME.LEFT_TOP_CORNER:
        canvas.style.cursor = "nw-resize";
        break;
      case CORNERS_SHAPE_NAME.TOP_MIDDLE_CORNER:
        canvas.style.cursor = "n-resize";
        break;
      case CORNERS_SHAPE_NAME.RIGHT_TOP_CORNER:
        canvas.style.cursor = "ne-resize";
        break;
      case CORNERS_SHAPE_NAME.LEFT_MIDDLE_CORNER:
        canvas.style.cursor = "w-resize";
        break;
      case CORNERS_SHAPE_NAME.RIGHT_MIDDLE_CORNER:
        canvas.style.cursor = "e-resize";
        break;
      case CORNERS_SHAPE_NAME.LEFT_BOTTOM_CORNER:
        canvas.style.cursor = "sw-resize";
        break;
      case CORNERS_SHAPE_NAME.BOTTOM_MIDDLE_CORNER:
        canvas.style.cursor = "s-resize";
        break;
      case CORNERS_SHAPE_NAME.RIGHT_BOTTOM_CORNER:
        canvas.style.cursor = "se-resize";
        break;
      default:
        break;
    }
  }

  setName(name) {
    this.name = name;
  }

  addShape(shapes: Shape[] = []) {
    this.shapes = this.shapes.concat(shapes);
  }

  setLiveMode(flag, gameState) {
    this.liveMode = flag;
    this.readyToStart = false;
    if (this.liveMode) {
      this.corners.shapes = [];
      this.corners.father = -1;

      this.readyToStart = true;
      let quandoIniciarScrpt = Object.keys(this.quandoIniciar ?? {});
      if (quandoIniciarScrpt?.length && this.liveMode) {
        this.playScript({
          type: quandoIniciarScrpt,
          script: this.quandoIniciar,
          name: "quandoIniciar",
          gameState,
        });
      }
    }
  }

  setInit(flag) {
    this.init = flag;
  }

  getWidth() {
    return this.width;
  }

  getHeight() {
    return this.height;
  }

  getContext() {
    return this.context;
  }

  getCanvas() {
    return canvas;
  }

  /**
   * Load corners with movement
   * @param {Mouse} mouse - Positions X e Y
   */
  loadCorners(mouse, corner, shape?: Shape) {
    if (this.isCurrentStage && this.shapeInUse?.id) {
      if (corner >= 0) {
        const myShape = shape || this.shapes?.[this.corners.father as any];
        this.oldX = myShape.x;
        this.oldY = myShape.y;

        const mx = mouse.x - (shape?.dragoffx ?? 0);
        const my = mouse.y - (shape?.dragoffy ?? 0);
        switch (corner) {
          case CORNERS_SHAPE_NAME.LEFT_TOP_CORNER:
            myShape.x = mx;
            myShape.y = my;
            myShape.width += this.oldX - mx;
            myShape.height += this.oldY - my;
            break;
          case CORNERS_SHAPE_NAME.TOP_MIDDLE_CORNER:
            myShape.y = my;
            myShape.height += this.oldY - my;
            break;
          case CORNERS_SHAPE_NAME.RIGHT_TOP_CORNER:
            myShape.y = my;
            myShape.width = mx - this.oldX;
            myShape.height += this.oldY - my;
            break;
          case CORNERS_SHAPE_NAME.LEFT_MIDDLE_CORNER:
            myShape.x = mx;
            myShape.width += this.oldX - mx;
            break;
          case CORNERS_SHAPE_NAME.RIGHT_MIDDLE_CORNER:
            myShape.width = mx - this.oldX;
            break;
          case CORNERS_SHAPE_NAME.LEFT_BOTTOM_CORNER:
            myShape.x = mx;
            myShape.width += this.oldX - mx;
            myShape.height = my - this.oldY;
            break;
          case CORNERS_SHAPE_NAME.BOTTOM_MIDDLE_CORNER:
            myShape.height = my - this.oldY;
            break;
          case CORNERS_SHAPE_NAME.RIGHT_BOTTOM_CORNER:
            myShape.width = mx - this.oldX;
            myShape.height = my - this.oldY;
            break;
          default:
            return;
        }

        if (myShape.height < playTEACreatorDimensions.minDimensionsShape) {
          myShape.height = playTEACreatorDimensions.minDimensionsShape;
        }

        if (myShape.width < playTEACreatorDimensions.minDimensionsShape) {
          myShape.width = playTEACreatorDimensions.minDimensionsShape;
        }

        this.createCorners(this.corners.father);
      }
    } else {
      this.corners.shapes = [];
      this.corners.father = -1;
    }
  }

  createCorners(father = -1) {
    if (this.liveMode) return;

    const defaultOptions: Pick<
      Shape,
      | "height"
      | "width"
      | "zIndex"
      | "visible"
      | "clickable"
      | "velocity"
      | "backGroundColor"
      | "matchId"
      | "id"
    > = {
      height: 15,
      width: 15,
      zIndex: 9999999,
      clickable: true,
      visible: true,
      velocity: 0,
      backGroundColor: "red",
    };
    const half = defaultOptions.width / 2;
    this.corners.shapes = [
      new Shape({
        ...defaultOptions,
        matchId: [],
        id: undefined,

        x: this.shapeInUse.x - half,
        y: this.shapeInUse.y - half,
      }),
      new Shape({
        ...defaultOptions,
        matchId: [],
        id: undefined,
        x: this.shapeInUse.x + this.shapeInUse.width / 2 - half,
        y: this.shapeInUse.y - half,
      }),
      new Shape({
        ...defaultOptions,
        matchId: [],
        id: undefined,
        x: this.shapeInUse.x + this.shapeInUse.width - half,
        y: this.shapeInUse.y - half,
      }),
      new Shape({
        ...defaultOptions,
        matchId: [],
        id: undefined,
        x: this.shapeInUse.x - half,
        y: this.shapeInUse.y + this.shapeInUse.height / 2 - half,
      }),
      new Shape({
        ...defaultOptions,
        matchId: [],
        id: undefined,
        x: this.shapeInUse.x + this.shapeInUse.width - half,
        y: this.shapeInUse.y + this.shapeInUse.height / 2 - half,
      }),
      new Shape({
        ...defaultOptions,
        matchId: [],
        id: undefined,
        x: this.shapeInUse.x - half,
        y: this.shapeInUse.y + this.shapeInUse.height - half,
      }),
      new Shape({
        ...defaultOptions,
        matchId: [],
        id: undefined,
        x: this.shapeInUse.x + this.shapeInUse.width / 2 - half,
        y: this.shapeInUse.y + this.shapeInUse.height - half,
      }),
      new Shape({
        ...defaultOptions,
        matchId: [],
        id: undefined,
        x: this.shapeInUse.x + this.shapeInUse.width - half,
        y: this.shapeInUse.y + this.shapeInUse.height - half,
      }),
    ];
    this.corners.father = father !== -1 ? father : this.shapeSelected;
  }

  // Draws the selection rectangle
  drawSelectionRectangle = ({
    selectionStartX,
    selectionStartY,
    selectionEndX,
    selectionEndY,
  }) => {
    // Figure out the top left corner of the rectangle
    const x = Math.min(selectionStartX, selectionEndX);
    const y = Math.min(selectionStartY, selectionEndY);

    // Calculate the width and height of the rectangle
    const width = Math.abs(selectionEndX - selectionStartX);
    const height = Math.abs(selectionEndY - selectionStartY);

    // Draw the rectangle
    this.selectedGroupShapes.width = width;
    this.selectedGroupShapes.height = height;
    this.selectedGroupShapes.x = x;
    this.selectedGroupShapes.y = y;
    this.selectedGroupShapes.visible = true;
  };

  updateSizeCanvas(isPlayTEA) {
    this.width = isPlayTEA
      ? Number($(window).width()) - 10
      : playTEACreatorDimensions.width;
    this.height = isPlayTEA
      ? Number($(window).height()) - 25
      : playTEACreatorDimensions.height;

    canvas.width = this.width;
    canvas.height = this.height;
    this.canvas = canvas;

    this.ghostCanvas = document.createElement("canvas");
    this.ghostCanvas.height = this.height;
    this.ghostCanvas.width = this.width;
    this.ghostctx = this.ghostCanvas.getContext("2d");
  }

  updateSizeShapes(padx, pady, alt_disp, larg_disp) {
    this.shapes?.forEach((shape) =>
      shape.resize(
        playTEACreatorDimensions.height,
        playTEACreatorDimensions.width,
        alt_disp,
        larg_disp,
        padx,
        pady
      )
    );
    if (this.shapeInUse?.id) this.createCorners();
  }

  resizeResolution() {
    const width_creator = playTEACreatorDimensions.width;
    const height_creator = playTEACreatorDimensions.height;
    let larg_disp = Number(this.width);
    let alt_disp = Number(this.height);
    let padx = 0,
      alt_mod = 0,
      larg_mod = 0,
      pady = 0;

    if (larg_disp < alt_disp) {
      alt_mod = larg_disp / (width_creator / height_creator);
      pady = (alt_disp - alt_mod) / 2;
      alt_disp = alt_mod;
    } else {
      larg_mod = (width_creator / height_creator) * alt_disp;
      padx = (larg_disp - larg_mod) / 2;
      larg_disp = larg_mod;
    }

    if (!this.resized) {
      this.updateSizeShapes(padx, pady, alt_disp, larg_disp);
      this.resized = true;
    }
  }

  getShapeSelected() {
    return this.shapeInUse || {};
  }

  clearSelectedGroupShapes() {
    this.selectedGroupShapes.visible = false;
    this.selectedGroupShapes.width = 0;
    this.selectedGroupShapes.height = 0;
    this.selectedGroupShapes.x = 0;
    this.selectedGroupShapes.y = 0;
  }
}
