import { Shape } from "../Shape";
import moment from "moment";
import { SCRIPT_NAMES, STAGE_NAMES } from "utils/enums";
import { RootStage } from "./RootStage";
import { CreateStageType } from "./create-stage.type";
import { shuffleShapes } from "../../../components/activities/memory";

let movingCorner = -1;
let canvas;

export class StagePairing extends RootStage {
  public initialMovement: any;
  public executingScript = false;
  public dragging = false;
  public shapeSelected = -1;
  public shapeInUse: any;
  public dragoffx = 0;
  public init = true;
  public acertosExecutados;
  public dragoffy = 0;
  public clone: any;
  public stageLiveMode: any;
  public readyToStart = false;
  public isMemoryGame = false;
  public shapesInMemory: Shape[] = [];
  public shuffleMemoryGame = false;

  constructor({
    canvasDoc,
    id,
    ids = [],
    shapes = [],
    quandoIniciar = [],
    quandoTerminar = [],
    acertosDoCenario = 0,
    isPlayTEA = false,
    mecanic = STAGE_NAMES.PAIRING,
    isMemoryGame = false,
    shuffleMemoryGame = false,
  }: CreateStageType) {
    super({
      id,
      canvasDoc,
      ids,
      shapes,
      quandoIniciar,
      quandoTerminar,
      acertosDoCenario,
      isPlayTEA,
      mecanic,
    });
    this.id = id;
    canvas = canvasDoc;
    this.context = canvas.getContext("2d");
    this.updateSizeCanvas(isPlayTEA);
    this.selectedShapes = [];
    this.shapes = [...shapes, this.selectedGroupShapes];
    this.ids = ids;
    this.initialMovement = null;
    this.isCurrentStage = false;
    this.executingScript = false;
    this.dragging = false;
    this.shapeSelected = -1;
    this.shapeInUse = null;
    this.dragoffx = 0;
    this.init = true;
    this.acertosExecutados = 0;
    this.acertosDoCenario = acertosDoCenario;
    this.dragoffy = 0;
    this.quandoIniciar = quandoIniciar;
    this.quandoTerminar = quandoTerminar;
    this.clone = null;
    this.stageLiveMode = null;
    this.shapesDeleteds = [];
    this.readyToStart = false;
    this.corners = {
      shapes: [],
      father: -1,
    };
    this.liveMode = false;
    this.actions = [];
    this.isMemoryGame = isMemoryGame;
    this.shapesInMemory = [];
    this.shuffleMemoryGame = shuffleMemoryGame;
  }

  clickAction(e): number {
    this.clear(this.ghostctx);

    movingCorner = super.clickAction(e);

    if (this.liveMode) {
      if (!this.readyToStart || this.shapesInMemory.length >= 2) {
        return -1;
      }

      if (this.shapeSelected !== -1) {
        this.clear(this.ghostctx);
        this.dragging = true;
      } else {
        this.dragging = false;
      }

      return movingCorner;
    }

    return movingCorner;
  }

  async start(force = false, gameState) {
    if (!this.looping || force) {
      this.isCurrentStage = true;

      this.clock = moment().format("YYYY-MM-DD HH:mm:ss.SSS");

      this.looping = setInterval(() => {
        this.draw();
      }, 1000 / this.fps);
    }
  }

  moveAction(e, gameState) {
    super.moveAction(e, gameState);
  }

  checkQuandoAcertar({ clone, shapeInUse, gameState }) {
    if (!clone.isMemoryShape || !shapeInUse.isMemoryShape) {
      this.shapes = this.shapes.filter(
        (shape) => shape.id !== clone.id && shape.id !== shapeInUse.id
      );

      const newShape = new Shape({
        ...shapeInUse,
        base64Image: shapeInUse.image64,
        used: true,
        x: clone.x,
        y: clone.y,
        keepBordersVisible: false,
        velocity: clone.velocity,
        width: clone.width,
        height: clone.height,
        matchId: clone.matchId,
        clickable: clone.clickable,
        quandoClicar: clone.quandoClicar,
        quandoAcertar: clone.quandoAcertar,
        quandoErrar: clone.quandoErrar,
        animated: false,
      });

      this.shapes.push(newShape);
    } else {
      shapeInUse.used = true;
      shapeInUse.keepBordersVisible = false;
    }

    clone.used = true;
    clone.keepBordersVisible = false;

    this.acertosExecutados++;
    let quandoAcertar = Object.keys(shapeInUse.quandoAcertar);
    if (quandoAcertar.length) {
      this.playScript({
        type: quandoAcertar,
        name: "quandoAcertar",
        script: shapeInUse.quandoAcertar,
        shapeSelected: shapeInUse,
        gameState,
      });
    }
    this.shapeInUse = null;
    if (this.acertosExecutados === Number(this.acertosDoCenario)) {
      this.acertosExecutados = 0;
      this.init = false;
      this.playScriptTerminar(null, gameState);
    }
  }

  checkMatchId({ shapeInUse, gameState }) {
    if (shapeInUse?.matchId?.length) {
      for (let i = 0; i < shapeInUse.matchId.length; i++) {
        let match = shapeInUse.matchId[i];
        let clone = this.shapes.find((e) => e.id === Number(match));
        if (clone && !clone.used) {
          if (
            shapeInUse.active &&
            clone.active &&
            (this.checkCollision(shapeInUse, clone, shapeInUse.dificult) ||
              this.checkCollision(clone, shapeInUse, shapeInUse.dificult))
          ) {
            this.checkQuandoAcertar({ clone, shapeInUse, gameState });
            break;
          }
        }
        if (i === this.shapeInUse?.matchId?.length - 1) {
          _errorStep({
            gameState,
            shapeSelected: shapeInUse,
            playScript: this.playScript,
          });
        }
      }
    } else {
      _errorStep({
        shapeSelected: shapeInUse,
        gameState,
        playScript: this.playScript,
      });
    }
  }

  shuffleMemoryShapes(changeAxis: boolean) {
    let shapesToShuffle = this.shapes.filter((shape) => shape.canShuffle);

    const shuffledShapes = shuffleShapes(
      shapesToShuffle,
      shapesToShuffle.length / 2
    );

    this.shapes.forEach((shape) => {
      const shuffleShape = shuffledShapes.find(
        (suffleShape) => suffleShape.id === shape.id
      );

      if (shuffleShape) {
        if (changeAxis) {
          shape.x = Number(shuffleShape?.x);
          shape.y = Number(shuffleShape?.y);
        }

        shape.hidden = shuffleShape?.canShuffle;
        shape.keepBordersVisible = shuffleShape?.canShuffle;
      }
    });
  }

  setLiveMode(flag, gameState) {
    this.liveMode = flag;
    this.readyToStart = false;
    if (this.liveMode) {
      if (this.isMemoryGame) {
        this.shuffleMemoryShapes(this.shuffleMemoryGame);
      }

      this.corners.shapes = [];
      this.corners.father = -1;

      this.readyToStart = true;
      let quandoIniciarScrpt = Object.keys(this.quandoIniciar ?? {});
      if (quandoIniciarScrpt?.length) {
        this.playScript({
          type: quandoIniciarScrpt,
          script: this.quandoIniciar,
          name: "quandoIniciar",
          gameState,
        });
      }
    }
  }

  async dropActionLiveMode({
    shapeInUse,
    gameState,
  }: {
    shapeInUse: Shape;
    gameState: any;
  }) {
    if (this.liveMode && this.isCurrentStage && this.init) {
      if (this.isMemoryGame && shapeInUse?.id) {
        if (this.shapesInMemory.length >= 2) {
          return;
        }

        let shapeInMemory = this.shapesInMemory.find(
          (shapeInMemory) => shapeInMemory.id === shapeInUse?.id
        );

        if (!shapeInMemory) {
          this.shapesInMemory.push(shapeInUse);
        }
      }

      if (shapeInUse && shapeInUse?.x === shapeInUse?.posInitialX) {
        let quandoClicar = Object.keys(shapeInUse.quandoClicar ?? {});
        if (quandoClicar.length) {
          this.playScript({
            type: quandoClicar,
            script: this.shapeInUse.quandoClicar,
            name: SCRIPT_NAMES.QUANDO_CLICAR,
            shapeSelected: shapeInUse.id,
            gameState,
            callbackActive: () => {
              this.checkMatchId({ shapeInUse, gameState });
            },
            callbackInactive: () => {
              this.shapesInMemory = this.shapesInMemory.filter(
                (shapeInMemory) => shapeInMemory.id !== shapeInUse.id
              );
            },
          });
        }
      } else {
        this.checkMatchId({ shapeInUse, gameState });
      }
    }
  }

  async dropAction(event: Event, gameState) {
    let { shapeInUse } = this;
    this.oldY = 0;
    this.oldX = 0;
    this.startMovement = null;
    this.dragging = false;

    if (this.liveMode) {
      await this.dropActionLiveMode({ shapeInUse, gameState });
    } else {
      super.dropAction(event, gameState);
    }
  }
}

const _errorStep = ({ shapeSelected, gameState, playScript }) => {
  if (!shapeSelected) return;

  let angle = Math.atan2(
    shapeSelected.posInitialY - shapeSelected.y,
    shapeSelected.posInitialX - shapeSelected.x
  );

  if (shapeSelected.velocity !== 5) {
    shapeSelected.velocityX = shapeSelected.velocity * Math.cos(angle);
    shapeSelected.velocityY = shapeSelected.velocity * Math.sin(angle);
  } else {
    shapeSelected.x = shapeSelected.posInitialX;
    shapeSelected.y = shapeSelected.posInitialY;
  }

  if (shapeSelected.quandoErrar) {
    let quandoErrar = Object.keys(shapeSelected.quandoErrar);
    if (quandoErrar.length) {
      playScript({
        type: quandoErrar,
        script: shapeSelected.quandoErrar,
        name: "quandoErrar",
        shapeSelected,
        gameState,
      });
    }
  }
};
