import React, { useEffect, useCallback } from "react";
import { useGameState } from "./classes/Game";
import { GameContext } from "../..";
import { STAGE_NAMES } from "utils/enums";
import { getStageByMecanic } from "./classes/stages/get-stage-by-mecanic";

export const LoadCanvas = ({
  selectedGame,
  liveMode = false,
  isPlayTEA = false,
  children: Component,
  endGameOnFinish = false,
  mecanic,
}) => {
  const game = useGameState({
    selectedGameName: selectedGame?.nome,
    isPlayTEA,
  });
  const initGame = useCallback(
    (mecanic: STAGE_NAMES) => {
      if (!selectedGame?.game && !game?.gameState.stages?.length) {
        const stage = getStageByMecanic(mecanic, {
          canvasDoc: document.querySelector("canvas"),
          id: 1,
          mecanic,
        });
        stage.setName("Fase principal");
        game?.gameState.setImage(selectedGame?.foto);
        game?.gameState.setCodigo(selectedGame?.game?.codigo);
        game.updateStages({ stages: [stage] });
        game.updateCurrentStage(stage);
        game?.gameState.setEvents(stage);
        stage.resizeResolution();
        if (liveMode) {
          stage.setLiveMode(true, game.gameState);
          stage.start(true, game.gameState, true);
        }
        return;
      }

      if (!game?.gameState.stages?.length) {
        const stages = game.create(selectedGame, isPlayTEA);

        game.updateStages({ stages });
        game?.gameState.setImage(
          selectedGame.foto?.data
            ? Buffer.from(selectedGame.foto.data).toString("utf-8")
            : selectedGame.foto
        );
        game.updateCurrentStage(stages[0]);
        game?.gameState.setEvents(stages[0]);
        game?.gameState.setCodigo(selectedGame.codigo);
        game.gameState.setEndGame(endGameOnFinish);
        stages[0].resizeResolution();
        if (liveMode) {
          stages[0].setLiveMode(true, game.gameState);
          stages[0].start(true, game.gameState, true);
        }
      }
    },
    [selectedGame, game, endGameOnFinish, liveMode, isPlayTEA]
  );

  useEffect(() => {
    if (mecanic) {
      initGame(mecanic);
    }
  }, [initGame, mecanic]);

  useEffect(() => {
    if (
      game?.gameState?.currentStage?.id &&
      !game?.gameState.currentStage?.isCurrentStage
    ) {
      game?.gameState.currentStage.start(
        false,
        game?.gameState,
        isPlayTEA || game?.gameState.currentStage.liveMode
      );
    }
  }, [game, isPlayTEA]);

  return (
    <GameContext.Provider value={{ game }}>{Component}</GameContext.Provider>
  );
};
