import axios from "axios";
import configureStore from "../redux/configure-store";
import { logoutAction } from "../redux/management/dispatchs";
import { logoutAction as logoutStudentAction } from "../redux/pupil/dispatchs";
import LoginService from "../redux/management/login";

export const axiosInstance = axios.create({
  withCredentials: true,
});

const { store } = configureStore();

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (!error?.response || error?.response?.status === 403) &&
      error?.response?.config?.url?.includes("session")
    ) {
      try {
        await Promise.all([
          LoginService.logout("aluno"),
          LoginService.logout("tutor"),
        ]);
        store.dispatch(logoutStudentAction());
        store.dispatch(logoutAction());
      } finally {
        window.location.href = "/";
      }
    }
    throw error;
  }
);
