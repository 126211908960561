import React, { useContext, useState } from "react";
import $ from "jquery";
import Grid from "antd/lib/card/Grid";
import { Row, Col, Input, Button, Modal, message, Radio, Checkbox } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { GameContext } from "../..";
import { If } from "react-extras";
import { v4 as uuidV4 } from "uuid";
import { ModalActions } from "./modal-actions";
import { IntroJS } from "../utils/tutorial";
import { addSound } from "./utils";
import "./styles.less";
import {
  CLEANUP_MECANICS_TYPE,
  PAINEL_ACTIONS_SCRIPTS,
  PAINEL_ACTIONS_SCRIPTS_NAME,
  SCRIPT_NAMES,
  STAGE_NAMES,
} from "utils/enums";
import { ScriptStep } from "./script-step";
import { generateRow } from "./painel-attrs-shape";
import { ChromePicker } from "react-color";

export const PainelAttrsStage = () => {
  const { game } = useContext(GameContext);
  const { gameState } = game;
  const [enableSteps, setEnabledSteps] = useState(false);
  const [visible, setVisible] = useState(false);
  const [script, setScript] = useState("");
  const [scriptSelecteds, setScriptSelecteds] = useState<any[]>([]);

  const createRowsAction = (stage: any, scriptName: string) => {
    const scriptToSearch = scriptSelecteds.find(
      (scriptSelected) => scriptSelected.key === scriptName
    );

    return (
      <Col span={4}>
        <Row>
          <Button
            size="small"
            className="add-script"
            onClick={() => {
              setVisible(true);
              setScript(scriptName);
            }}
          >
            <i className="fa fa-plus" aria-hidden="true" />
          </Button>
        </Row>
        <Row>
          <Button
            size="small"
            className="delete-script"
            disabled={!scriptToSearch}
            onClick={() => {
              if (scriptToSearch) {
                stage[scriptToSearch.key] = stage[scriptToSearch.key]
                  .filter((script) => script.id !== scriptToSearch.script)
                  .map((script, index) => ({ ...script, order: index + 1 }));
              }

              setScriptSelecteds([]);
              game.updateCurrentStage(stage, gameState.stages);
            }}
          >
            <i className="fa fa-minus" aria-hidden="true" />
          </Button>
        </Row>
        {/* <Row>
          <Button
            size="small"
            disabled={!scriptToSearch}
            className="view-script-detail"
          >
            <i className="fa fa-eye" aria-hidden="true" />
          </Button>
        </Row> */}
      </Col>
    );
  };

  const assignScriptStage = ({ actionElement, actionStage, action }) => {
    const { currentStage } = gameState;
    const id = uuidV4();
    let errorMessage = "";
    const input = $("<input/>")
      .attr("type", "file")
      .attr("name", "file")
      .attr("id", "add-script");

    currentStage[script] = currentStage[script] ?? [];

    const order = currentStage[script].length + 1;

    let scriptToSave: Record<string, any> = {};
    switch (Number(action)) {
      case 0:
        break;
      case PAINEL_ACTIONS_SCRIPTS.NEW_AUDIO:
        input.attr("accept", "audio/*");
        input.trigger("click");
        input.on("change focus", async function () {
          const self: any = this;
          if (self.files?.length) {
            const sound = await addSound(self.files[0]);
            scriptToSave = {
              id,
              type: PAINEL_ACTIONS_SCRIPTS_NAME.NEW_AUDIO,
              name: `Som - ${self.files[0].name}`,
              value: sound,
            };
            currentStage[script].push(scriptToSave);
            game.updateCurrentStage(gameState.currentStage, gameState.stages);
          }
        });
        break;
      case PAINEL_ACTIONS_SCRIPTS.PREVIOUS_STAGE:
        scriptToSave = {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.PREVIOUS_STAGE,
          name: "Vai para fase anterior",
          value: true,
        };
        break;
      case PAINEL_ACTIONS_SCRIPTS.NEXT_STAGE:
        scriptToSave = {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.NEXT_STAGE,
          name: "Vai para próxima fase",
          value: true,
        };
        break;
      case PAINEL_ACTIONS_SCRIPTS.SPECIFY_STAGE:
        if (actionStage) {
          scriptToSave = {
            type: PAINEL_ACTIONS_SCRIPTS_NAME.SPECIFY_STAGE,
            name: `Vai para fase - ${actionStage}`,
            value: actionStage,
          };
        } else errorMessage = "Por favor, selecione uma fase";
        break;
      case PAINEL_ACTIONS_SCRIPTS.END_GAME:
        scriptToSave = {
          type: PAINEL_ACTIONS_SCRIPTS_NAME.END_GAME,
          name: "Terminar jogo",
          value: true,
        };
        break;
      case PAINEL_ACTIONS_SCRIPTS.SHOW_SHAPE:
        if (actionElement) {
          scriptToSave = {
            type: PAINEL_ACTIONS_SCRIPTS_NAME.SHOW_SHAPE,
            name: `Mostrar figura - ${actionElement}`,
            value: actionElement,
          };
        } else errorMessage = "Por favor, selecione uma figura";
        break;
      case PAINEL_ACTIONS_SCRIPTS.HIDE_SHAPE:
        if (actionElement) {
          scriptToSave = {
            type: PAINEL_ACTIONS_SCRIPTS_NAME.HIDE_SHAPE,
            name: `Esconder figura - ${actionElement}`,
            value: actionElement,
          };
        } else errorMessage = "Por favor, selecione uma figura";
        break;
      case PAINEL_ACTIONS_SCRIPTS.AWAITING_TIME:
        if (actionElement) {
          scriptToSave = {
            type: PAINEL_ACTIONS_SCRIPTS_NAME.AWAITING_TIME,
            name: `Aguardar tempo - ${actionElement}`,
            value: actionElement,
          };
        } else errorMessage = "Por favor, insira um valor";
        break;
      case PAINEL_ACTIONS_SCRIPTS.INACTIVE_SHAPE:
        if (actionElement) {
          scriptToSave = {
            type: PAINEL_ACTIONS_SCRIPTS_NAME.INACTIVE_SHAPE,
            name: `Inativar elemento - ${actionElement}`,
            value: actionElement,
          };
        } else errorMessage = "Por favor, insira um valor";
        break;
      case PAINEL_ACTIONS_SCRIPTS.ACTIVE_SHAPE:
        if (actionElement) {
          scriptToSave = {
            type: PAINEL_ACTIONS_SCRIPTS_NAME.ACTIVE_SHAPE,
            name: `Ativar Elemento - ${actionElement}`,
            value: actionElement,
          };
        } else errorMessage = "Por favor, insira um valor";
        break;
      default:
        break;
    }

    if (errorMessage) {
      message.info(errorMessage);
    } else {
      if (!scriptToSave.id && scriptToSave.value) {
        scriptToSave.order = order;
        scriptToSave.id = id;

        currentStage[script].push(scriptToSave);
        game.updateCurrentStage(gameState.currentStage, gameState.stages);
      }
      setVisible(false);
    }
  };

  const renderScriptList = (script: string) => {
    if (gameState.currentStage?.id) {
      return gameState.currentStage[script]?.map((scriptAction) => ({
        value: scriptAction.id,
        order: scriptAction.order,
        label: scriptAction.name,
      }));
    }
  };
  const confirmDeleteModal = (id) => {
    Modal.warn({
      title: "Tem certeza que deseja deletar?",
      cancelText: "Voltar",
      okCancel: true,
      onOk: () => {
        game.pauseStages(gameState.stages);
        const stages = gameState.stages.filter((stage: any) => stage.id !== id);
        game.updateStages({ stages });
        const [stage] = stages;
        game.updateCurrentStage(stage);
        gameState.setEvents(stage);
        stage.start(true, gameState);
        message.success("Fase excluida com sucesso");
      },
    });
  };

  return (
    <Grid
      style={{
        width: "100%",
        maxHeight: "600px",
        overflow: "scroll",
        maxWidth: $(window).width(),
      }}
    >
      <IntroJS
        steps={[]}
        stepsEnabled={enableSteps}
        onExit={() => setEnabledSteps(false)}
      />
      <Row
        id="step13"
        align="middle"
        justify="center"
        style={{
          marginTop: "-10px",
          marginBottom: "15px",
          fontWeight: "bold",
        }}
      >
        Configurações da fase atual
        <div className="tutorial-stage" onClick={() => setEnabledSteps(true)}>
          {" "}
          (Ver tutorial){" "}
        </div>
      </Row>
      <Row id="step13" style={{ marginBottom: "10px" }}>
        <Col span={12}> Nome: </Col>
        <Col span={12}>
          <Input
            type="text"
            value={gameState?.currentStage?.name}
            onChange={({ target: { value } }) => {
              gameState.currentStage.setName(value);
              game.draw(gameState);
            }}
          />
        </Col>
      </Row>
      <Row id="step14" style={{ marginBottom: "10px" }}>
        <Col span={12}> Acertos passar de fase? </Col>
        <Col span={12}>
          <Input
            type="number"
            value={gameState?.currentStage?.acertosDoCenario}
            onChange={(event: any) => {
              gameState.currentStage.acertosDoCenario = event.target.value;
              game.draw(gameState);
            }}
          />
        </Col>
      </Row>
      <Row id="step15">
        <ScriptStep
          createRowsAction={createRowsAction}
          gameState={gameState}
          keyScript={SCRIPT_NAMES.QUANDO_INICIAR}
          game={game}
          selectedScript={scriptSelecteds.find(
            (scriptSelected) =>
              scriptSelected.key === SCRIPT_NAMES.QUANDO_INICIAR
          )}
          renderScript={renderScriptList}
          scriptName="QuandoIniciar?"
          setScriptSelecteds={setScriptSelecteds}
        />
      </Row>
      <Row id="step15" style={{ marginTop: "10px" }}>
        <ScriptStep
          createRowsAction={createRowsAction}
          gameState={gameState}
          selectedScript={scriptSelecteds.find(
            (scriptSelected) =>
              scriptSelected.key === SCRIPT_NAMES.QUANDO_TERMINAR
          )}
          game={game}
          keyScript={SCRIPT_NAMES.QUANDO_TERMINAR}
          renderScript={renderScriptList}
          scriptName="QuandoTerminar?"
          setScriptSelecteds={setScriptSelecteds}
        />
      </Row>
      <If condition={gameState?.currentStage?.mecanic === STAGE_NAMES.PAINTING}>
        <Row align="middle" justify="start" style={{ marginBottom: 15 }}>
          <Col span={8}> Tipo de execução </Col>
          <Col span={12}>
            <Radio.Group
              onChange={(event) => {
                const value = event.target.value;
                gameState.currentStage.colorPainting = "";
                if ([CLEANUP_MECANICS_TYPE.DELETING].includes(value)) {
                  gameState.currentStage.colorPainting = "#ffffff";
                }
                gameState.currentStage.paintingType = value;
                game.draw(gameState);
              }}
              value={gameState?.currentStage?.paintingType}
            >
              <Radio value={CLEANUP_MECANICS_TYPE.PAINTING}>Pintar</Radio>
              <Radio value={CLEANUP_MECANICS_TYPE.DELETING}>Limpar</Radio>
            </Radio.Group>
          </Col>
        </Row>
      </If>

      <If
        condition={
          gameState?.currentStage?.paintingType ===
          CLEANUP_MECANICS_TYPE.PAINTING
        }
      >
        {generateRow({
          id: "step-fig19",
          children: (
            <ChromePicker
              color={gameState.currentStage?.colorPainting}
              onChange={(color: any) => {
                gameState.currentStage.colorPainting = color.hex;
                game.draw(gameState);
              }}
            />
          ),
          title: "Cor da pintura",
        })}
      </If>

      <If condition={gameState?.currentStage?.isMemoryGame}>
        {generateRow({
          id: "step-fig70",
          children: (
            <Checkbox
              className="form-input"
              name="shuffle"
              checked={gameState?.currentStage?.shuffleMemoryGame}
              onChange={(event) => {
                gameState.currentStage.shuffleMemoryGame = event.target.checked;
                game.draw(gameState);
              }}
              tabIndex={2}
            />
          ),
          title: "Embaralhar?",
        })}
      </If>

      <Row
        id="step15"
        style={{ marginTop: "10px" }}
        align="middle"
        justify="center"
      >
        <If condition={gameState?.currentStage?.id !== 1}>
          Excluir fase:
          <DeleteOutlined
            style={{ marginLeft: "10px" }}
            className="icon-trash"
            twoToneColor="#eb2f96"
            onClick={() => confirmDeleteModal(gameState.currentStage.id)}
          />
        </If>
      </Row>
      <ModalActions
        noFig={false}
        assignScript={assignScriptStage}
        script={script}
        setVisible={setVisible}
        visible={visible}
      />
    </Grid>
  );
};
