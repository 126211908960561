import React, { createContext, useCallback, useEffect, useState } from "react";
import { CanvasOptions } from "./canvas-options";
import { Canvas } from "./canvas";
import { Col, message, Row } from "antd";
import { Navigator } from "./navigator";
import { LoadCanvas } from "./canvas/game/load";
import { useLocation } from "react-router-dom";
import { useScreenOrientation } from "../../../hooks/useScreenOrientation";
import { PostMessagesTypes, ScreenOrientation } from "../../../enums";
import { Choose } from "react-extras";
import { LoaderBlock } from "../../../components/loader-block";
import { ScenesAvailable } from "./scenes-available";
import { useDispatch } from "react-redux";
import { CREATE_SHAPE_FROM_PASTE } from "../../../redux/games/actions";
import $ from "jquery";
import "./vendor/bootstrap.min.css";
import "./css/chat.css";
import "./css/canvas.css";
import "./css/introjs-rtl.min.css";
import "./css/introjs.min.css";
import "./css/painel-canvas.css";
import "./styles.less";
import { ModalMecanics } from "./canvas/components/modal-mecanics";

export const GameContext = createContext<Record<string, any>>({});

const sleep = (timer: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(timer), timer);
  });
};

export const CreateGame = () => {
  const location = useLocation<{
    game?: Record<string, any>;
    scene?: boolean;
  }>();
  const [loading, setLoading] = useState(true);
  const [canvasOptions, showCanvasOptions] = useState(false);
  const [modalScenes, setModalScenes] = useState(false);
  const [mecanic, setMecanic] = useState("");
  const dispatch = useDispatch();
  useScreenOrientation({ orientation: ScreenOrientation.LANDSCAPE });
  useEffect(() => {
    const reloaded = localStorage.getItem("reload-create-game");
    const eventHandler = async (event) => {
      if (event.data.type === PostMessagesTypes.ScreenOrientation) {
        await sleep(500);
        setLoading(false);
      }
    };

    if (reloaded !== "true") {
      localStorage.setItem("reload-create-game", "true");
      window.location.reload();
    }

    window.addEventListener("message", eventHandler);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }, []);

  const pasteShape = useCallback(
    (data) => {
      try {
        const shapes = JSON.parse(data);
        dispatch({
          type: CREATE_SHAPE_FROM_PASTE,
          payload: shapes,
        });
      } catch (err) {
        message.info("Midia não suportada");
      }
    },
    [dispatch]
  );

  const propsOption = Number($(window).width()) >= 1250 && { span: 7 };

  useEffect(() => {
    if (location.state?.game?.nome) {
      const { stages } = JSON.parse(location.state.game?.game);
      setMecanic(stages[0].mecanic);
    }
  }, [location]);

  return (
    <Choose>
      <Choose.When condition={loading}>
        <LoaderBlock spinning text="Carregando plataforma" />
      </Choose.When>
      <Choose.Otherwise>
        <ModalMecanics
          visible={!location.state?.game?.codigo && !mecanic}
          showFooter={false}
          setVisible={() =>
            message.info("Você precisa selecionar uma mecânica")
          }
          action={(mecanic) => setMecanic(mecanic)}
        />
        <LoadCanvas selectedGame={location.state?.game} mecanic={mecanic}>
          <Row
            style={{ width: "100%", padding: "0px 10px 0px 10px" }}
            onPaste={({ clipboardData }) => {
              const data = clipboardData.getData("text/plain");
              pasteShape(data);
            }}
          >
            <Navigator
              isScene={Boolean(location?.state?.scene)}
              onClickCanvasOptions={() => showCanvasOptions(true)}
              onClickScenes={() => setModalScenes(true)}
            />
          </Row>
          <Row
            style={{
              display: "flex",
              overflowX: "scroll",
              width: "100%",
            }}
          >
            <Col
              className="col-canvas"
              style={{
                marginLeft: 10,
                maxWidth: 1290,
                overflow: "scroll",
              }}
            >
              <Canvas />
            </Col>
            <Col {...propsOption} style={{ marginTop: 10, width: "100%" }}>
              <CanvasOptions
                canvasOptions={canvasOptions}
                showCanvasOptions={showCanvasOptions}
              />
            </Col>

            {!location?.state?.scene && (
              <div style={{ padding: 10 }}>
                <ScenesAvailable
                  modalScenes={modalScenes}
                  setModalScenes={setModalScenes}
                />
              </div>
            )}
          </Row>
        </LoadCanvas>
      </Choose.Otherwise>
    </Choose>
  );
};
