import React, { createContext, useEffect, useState } from "react";
import "./css/chat.css";
import { Canvas } from "./canvas";
import { LoadCanvas } from "./canvas/game/load";
import { useLocation } from "react-router-dom";
import { useScreenOrientation } from "../../../hooks/useScreenOrientation";
import { PostMessagesTypes, ScreenOrientation } from "../../../enums";
import { Choose } from "react-extras";
import { LoaderBlock } from "../../../components/loader-block";
import { Button, Row, Typography } from "antd";
import { PAINEL_ACTIONS_SCRIPTS_NAME } from "utils/enums";
import { loadAudio } from "./canvas/game/classes/utils";
import gameTest from "assets/games/game-test.json";

const sleep = (timer: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(timer), timer);
  });
};

export const LoadContext = createContext<any>({});

export const loadSounds = (script) => {
  const scriptToCheckSounds = Object.keys(script);

  for (const key of scriptToCheckSounds) {
    if (script[key].type === PAINEL_ACTIONS_SCRIPTS_NAME.NEW_AUDIO) {
      script[key] = loadAudio(script[key], true);
    }
  }

  return script;
};

export const startSounds = (gameToReady) => {
  for (const stage of gameToReady.stages) {
    stage.quandoIniciar = loadSounds(stage.quandoIniciar);
    stage.quandoTerminar = loadSounds(stage.quandoTerminar);

    stage.shapes.forEach((shape) => {
      shape.quandoClicar = loadSounds(shape.quandoClicar);
      shape.quandoAcertar = loadSounds(shape.quandoAcertar);
      shape.quandoErrar = loadSounds(shape.quandoErrar);
    });
  }

  return gameToReady;
};

export const CreateGamePlayTEA = () => {
  const location: any = useLocation();
  const [loading, setLoading] = useState(true);
  const [readyToStartGame, setReadyStartGame] = useState(false);
  const [gameToPlay, setPlayableGame] = useState<any>();

  const game = location?.state?.game ?? gameTest;

  const startGame = () => {
    const gameToReady = JSON.parse(game.game);

    startSounds(gameToReady);
    setReadyStartGame(true);
    setPlayableGame(gameToReady);
  };

  useScreenOrientation({ orientation: ScreenOrientation.LANDSCAPE });

  useEffect(() => {
    const reloaded = localStorage.getItem("reload-run-game");
    const eventHandler = async (event) => {
      if (event.data.type === PostMessagesTypes.ScreenOrientation) {
        await sleep(1000);
        setLoading(false);
      }
    };
    window.addEventListener("message", eventHandler);
    if (reloaded !== "true") {
      localStorage.setItem("reload-run-game", "true");
      window.location.reload();
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }, []);

  return (
    <Choose>
      <Choose.When condition={loading}>
        <LoaderBlock spinning text="Carregando jogo" />
      </Choose.When>
      <Choose.When condition={!loading && !readyToStartGame}>
        <Row
          align="middle"
          justify="center"
          style={{
            width: "100%",
            textAlign: "center",
            padding: 60,
          }}
        >
          <Typography.Title level={4}>
            Jogo carregado com sucesso, clique abaixo para começar.
          </Typography.Title>
        </Row>
        <Row align="middle" justify="center" style={{ width: "100%" }}>
          <Button onClick={startGame} type="primary" style={{ width: "200px" }}>
            Começar Jogo
          </Button>
        </Row>
      </Choose.When>
      <Choose.Otherwise>
        <LoadCanvas
          liveMode
          isPlayTEA
          endGameOnFinish
          mecanic={gameToPlay?.type}
          selectedGame={{ ...game, game: gameToPlay }}
        >
          <Canvas />
        </LoadCanvas>
      </Choose.Otherwise>
    </Choose>
  );
};
